@import '~styles/variables';

.NavLinkGroup {
  &-children {
    margin-left: 40px;
    margin-top: -10px;

    a {
      display: block;
      font-size: 14px;
      text-decoration: none;
      color: $text-color-primary;
      margin-top: 5px;

      &.NavSubLink {
        font-weight: 600;
      }
    }
  }
}
