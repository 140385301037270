.RolloverKeyForm {
  &-key {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
  }

  &-desc {
    margin-bottom: 20px;
    font-size: 14px;
  }
}
