@import '~styles/variables';

.CompanyFeatures {
  .list-header {
    margin-bottom: 10px;

    div {
      .Button {
        margin-top: 0;
      }
    }
  }
}
