@import '~styles/variables';

.AirGap {
  .row {
    &.air-gapped {
      .Button {
        margin: 0;
      }

      .air-gap-loading {
        .ContentLoader {
          padding: 10px;
          text-align: left;

          &-spinner {
            width: 20px;
            height: 20px;
          }
        }
      }

      .air-gap-no-key {
        margin-top: 10px;
        font-weight: 600;
        font-size: 14px;
        color: $text-color-secondary;
      }
    }

    h3 {
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }

    .descriptive-header {
      display: flex;

      svg {
        margin-left: 5px;
      }
    }
  }
}
