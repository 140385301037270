@import '~styles/variables';

.RichTextEditor {
  position: relative;
  min-width: 300px;
  border: 1px solid $form-element-border;
  background-color: #fff;

  .tiptap {
    min-height: 150px;
    width: 100%;
    outline: none;
    padding: 0 10px 10px 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    resize: none;
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;

    p.is-editor-empty:first-child::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    p {
      &:first-of-type {
        margin-top: 6px;
      }
    }

    img {
      max-width: 500px;
      object-fit: contain;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
      margin-top: 2.5rem;
      text-wrap: pretty;
    }

    h1,
    h2 {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1rem;
    }
  }
}
