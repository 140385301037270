@import '~styles/variables';
@import '~styles/helpers';

.ColorPicker {
  position: relative;
  display: inline-flex;

  .color-select {
    @include appearance(none);
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0px 10px 0px 0px;
    border: 1px solid #eaeaea;
    min-width: 100px;

    .color-container {
      height: 22px;
      width: 22px;
      margin-right: 10px;
      border-right: 1px solid #eaeaea;
    }
  }

  .Dropdown {
    padding: 0;
    left: 0;
    right: unset;
    box-shadow: none;
    border: 1px solid #eaeaea;

    .chrome-picker {
      border-radius: 0 !important;
      box-shadow: none !important;

      div {
        border-radius: 0 !important;
      }
    }
  }
}
