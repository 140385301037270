@import '~styles/variables';

.CheckboxSelector {
  position: relative;
  max-width: 400px;
  font-size: 14px;
  height: auto;
  min-height: 34px;
  max-height: none;

  .selector-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 8;
    pointer-events: none;

    svg {
      margin-right: 10px;
    }
  }

  .selector-inner__control {
    .selector-inner__multi-value {
      display: none;
    }

    .selector-inner__input {
      display: block !important;
      position: relative;

      input {
        width: 100% !important;
        cursor: pointer;
        color: transparent;
      }
    }
  }

  .selector-inner__option {
    display: flex;
    align-items: center;
    padding: 5px;

    .checkbox-option {
      display: flex;
      flex-shrink: 0;
    }

    &:hover {
      input {
        border: 1px solid $form-element-border-focus;
      }
    }

    label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-weight: 400;
      font-size: 13px;
      color: $text-color-primary;
      text-align: left;
    }

    input {
      position: relative;
      appearance: none;
      height: 18px;
      width: 18px;
      border: 1px solid $form-element-border;
      outline: none;
      vertical-align: bottom;
      cursor: pointer;
      margin-right: 6px;
      background-color: #fff;

      &.has-error {
        border-color: $red;
      }

      &:checked {
        border: 1px solid $form-element-border-focus;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHRpdGxlPmljb25zPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjEgMzIuMzUgNi4zOCAyNC43MSAxOS41MyAzMy45OCA0MS4zNiAzIDQ5IDguMzggMjEuNzkgNDcgMSAzMi4zNSIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }

  .selector-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* Make main select container take up its own space */
  .selector-container {
    width: 100%;
    margin-bottom: 8px;
  }

  .selected-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    position: static; // Change from absolute to static
    width: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .selected-pill {
    display: inline-flex;
    align-items: center;
    background-color: #f3f4f6;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 14px;
    max-width: 100%;

    .pill-label {
      margin-right: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pill-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      padding: 2px;
      cursor: pointer;
      color: #6b7280;
      border-radius: 50%;
      transition: all 0.2s ease;

      &:hover {
        background-color: #e5e7eb;
        color: #4b5563;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
      }
    }
  }
}
