@import '~styles/variables';

.CustomerAccountSSO {
  .row {
    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 18px;
      color: $text-color-primary;
    }
  }

  .redirect-uri-cell {
    display: flex;
    align-items: center;

    .Button {
      margin-left: 20px;
    }
  }

  .account-verification-switcher {
    display: flex;
    align-items: center;

    .Label {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}
