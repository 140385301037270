@import '~styles/variables';
@import '~styles/helpers';

.BundleLicenseContainer {
  .docs-link {
    display: flex;
    margin: 10px 0;

    p {
      margin: 0;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      margin-left: 3px;
    }
  }

  .LicenseUsers {
    margin-bottom: 20px;
  }
}
