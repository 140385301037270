.LicenseContainer {
  .Notice {
    .docs-link {
      display: flex;
      align-items: baseline;

      p {
        margin: 0 4px 0 0;
      }
    }
  }

  .license-title-container {
    display: flex;
    align-items: center;

    .clipboard-btn {
      display: inline-flex;
      align-items: center;
      z-index: 2;
      background-color: transparent;
      opacity: 0.5;
      -webkit-appearance: none;
      border: none;
      width: 34x;
      cursor: pointer;
      outline: none;
      margin-left: 10px;

      &:hover {
        background-color: #e2e2e2;
        opacity: 1;
      }
    }
  }

  .license-user-tab {
    .license-user-tab-actions {
      display: flex;
      justify-content: space-between;

      .max-license-users-count {
        font-weight: 500;
        font-size: 14px;
        background: #ffffff;
        padding: 5px 18px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
      }
    }
  }

  .devices-list {
    .List .ReactTable .rt-tbody .rt-tr {
      cursor: default;
    }
  }

  .blacklisted-devices-list {
    margin-top: 40px;

    .Subtitle {
      font-size: 18px;
    }
  }

  &-tabs {
    .Tab-header {
      font-weight: 500;
      font-size: 15px;
      max-width: 600px;
      margin-bottom: 20px;
    }
  }

  .Device-variables-header {
    display: flex;

    svg {
      margin-left: 5px;
    }
  }
}
