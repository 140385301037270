@import '~styles/variables';

.CustomerContainer {
  .lists-cont {
    background: #fff;
    border: 1px solid $form-element-border;
  }

  .Logs {
    margin-top: 20px;
  }
}

.customer-title-container {
  .labels {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .Button {
      margin-bottom: 0;
    }
  }

  .customer-title {
    display: flex;
    align-items: center;

    .clipboard-btn {
      display: inline-flex;
      align-items: center;
      z-index: 2;
      background-color: transparent;
      opacity: 0.5;
      -webkit-appearance: none;
      border: none;
      width: 34x;
      cursor: pointer;
      outline: none;
      margin-left: 10px;

      &:hover {
        background-color: #e2e2e2;
        opacity: 1;
      }
    }
  }

  .customer-company-desc {
    color: $text-color-secondary;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
