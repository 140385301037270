// Colors
$blue: #2e86de;
$green: #10ac84;
$gray: #707070;
$orange: #ff9f43;
$red: #ee5253;
$yellow: #feca57;
$white: #ffffff;
$background: #fafafa;

// Text definition
$text-color-primary: #464854;
$text-color-secondary: lighten($text-color-primary, 5);
$text-color-error: darken($red, 12);
$font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif;

$shadow: 1px 1px 4px rgba(#000, 0.1);

$Sidebar-width: 260px;
$Sidebar-width-sm: 70px;
$TopHeader-height: 50px;
$logo-width: 50px;

// form elements
$form-element-border: #e3e3e3;
$form-element-border-focus: #44486c;
$form-element-max-width: 300px;
$form-element-height: 34px;
$form-element-disabled-background: #f5f5f5;
$form-element-disabled-border: #ccc;

$select-option-hover: lighten($blue, 20%);
$option-hover-background: #eee;
$option-selected-background: lighten($blue, 30%);

$default-transition: 150ms all ease-in-out;

// Shadows
$box-shadow-xs: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
$box-shadow-s: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 96%, 0.12);
$box-shadow-md: 0 10px 20px hsla(0, 0%, 0%, 0.15),
  0 3px 6px hsla(0, 0%, 0%, 0.1);
$box-shadow-lg: 0 15px 25px hsla(0, 0%, 0%, 0.15),
  0 5px 10px hsla(0, 0%, 0%, 0.15);
$box-shadow-xl: 0 20px 40px hsla(0, 0%, 0%, 0.2);
