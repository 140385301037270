@import '~styles/variables';

.ContentLoader {
  padding: 50px 0;
  text-align: center;

  &-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: $gray;
    animation: spin 750ms linear infinite;
    -webkit-animation: spin 750ms linear infinite;
  }

  &-text {
    margin-top: 20px;
    font-size: 14px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
