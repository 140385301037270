@import '~styles/variables';

.DebugSwitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  // background: #ececec;
  position: relative;

  .Label {
    margin: 0 20px 0 0;

    svg {
      fill: $text-color-primary;
    }
  }

  .Switcher {
    height: 20px;
  }
}
