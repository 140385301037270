.DevicesList {
  .SubComponent {
    &-actions {
      margin-top: 10px;
    }

    &-users {
      margin: 0 20px 20px;
      background: #fff;
      padding: 10px;
      padding-top: 14px;

      .heading {
        font-weight: 600;
        font-size: 14px;
      }

      ul {
        margin-block-start: 5px;
        padding-inline-start: 20px;
      }
    }
  }
}
