@import '~styles/variables';

.TextInput {
  position: relative;

  input {
    height: $form-element-height;
    width: 100%;
    max-width: $form-element-max-width;
    min-width: 300px;
    border: 1px solid $form-element-border;
    outline: none;
    padding: 0 10px;
    color: $text-color-primary;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif;

    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:disabled {
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;
    }

    &.has-error {
      border-color: $red;
    }
  }

  .Spinner {
    position: absolute;
    right: 0;
    top: 4px;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
