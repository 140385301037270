@import '~styles/variables';
@import '~styles/helpers';

.LicenseManagerProduct {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid $form-element-border;
  border-left: 4px solid $gray;
  padding: 10px 15px;
  font-size: 13px;
  color: $text-color-primary;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .product-name {
      font-size: 13px;
      font-weight: 600;
    }

    .actions {
      .Button {
        margin-bottom: 0;
        margin-left: 20px;
      }
    }
  }

  .details {
    display: flex;

    .label {
      width: 30%;
      min-width: 100px;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: $text-color-primary;
      font-weight: 500;
    }

    .value {
      width: 70%;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: $text-color-secondary;
      font-weight: 400;
    }
  }
}
