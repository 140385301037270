@import '~styles/variables';

.Sidebar {
  position: fixed;
  z-index: -1;
  top: $TopHeader-height;
  background: #fff;
  height: calc(100vh - #{$TopHeader-height});
  width: $Sidebar-width;
  box-sizing: border-box;
  border-right: 1px solid rgba($gray, 0.2);
  float: left;
  overflow-y: auto;

  a {
    text-decoration: none;
    font-size: 14px;
    color: $text-color-primary;
    display: block;
    margin: 10px 0;

    &:hover {
      color: #111;
    }

    &.active {
      color: $blue;
      font-weight: 500;

      &:hover {
        color: $blue;
      }

      .NavLink-submenu {
        display: block;
      }

      svg {
        fill: $blue;
      }
    }
  }

  .toggle-container {
    position: absolute;
    right: 0;
    top: 20px;

    button {
      cursor: pointer;
      appearance: none;
      padding: 0;
      border: 1px solid #eaeaea;
      border-right: none;
      outline: none;
      background: $background;

      svg {
        width: 20px;
        height: 20px;
        stroke: #464854;
        stroke-width: 2;
      }
    }
  }

  &-sm {
    width: $Sidebar-width-sm;

    .toggle-container {
      top: 0;
    }

    .Company-plan {
      display: none;
    }

    .CompanySidebar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 30px 0;

      .NavLink {
        header {
          margin: 0;

          .NavLink-icon {
            width: unset;
            margin-right: 0;

            svg {
              height: 24px;
              width: 24px;
            }
          }

          .NavLink-text {
            display: none;
          }
        }
      }

      .NavLinkGroup-children {
        display: none;
      }
    }

    .version-cont__sidebar {
      width: 58px;

      .label {
        display: none;
      }
    }
  }
}
