@import '~styles/variables';

.SlidePane-overlay {
  z-index: 99;
}

.SlidePane {
  max-width: 1400px;

  .slide-pane__header {
    background: #f3f4f5;
    flex-basis: 60px;
    height: 60px;

    .slide-pane__close {
      margin-left: 16px;

      svg {
        width: 20px;
      }
    }

    .slide-pane__title-wrapper {
      .slide-pane__title {
        font-size: 24px;
        font-weight: 500;
        color: #464854;
        max-width: 100%;
        padding-right: 40px;
      }
    }
  }

  .slide-pane__content {
    padding: 12px 24px;
  }
}
