@import '~styles/variables';

.ConsentForm {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .consent-form-wrapper {
    max-width: 60vw;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and(max-width: 800px) {
      max-width: 100%;
    }

    .consent-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .row {
        display: flex;
        align-items: flex-start;
        margin: 20px;

        p {
          margin: 0;
        }

        a {
          margin: 0 5px;
        }

        &.logo-cont {
          margin: 0;
        }

        &.title-cont {
          margin-top: 5px;

          h1 {
            margin: 0;
            font-weight: 500;
            font-size: 30px;
            text-align: center;
          }
        }

        &.subtitle-cont {
          p {
            font-size: 21px;
            font-weight: 500;
            color: $text-color-secondary;
          }
        }
      }
    }
  }
}
