@import '~styles/variables';

.ProductFeaturesEditableTable {
  margin-top: 30px;

  .List {
    .InputErrorMessage {
      display: none;
    }

    .rt-table {
      overflow: visible;
    }

    .rt-tbody {
      overflow: visible;
    }

    .rt-tr {
      overflow: visible;
    }

    .rt-td {
      overflow: visible;

      .Selector {
        min-width: 100px;
      }

      .NumberInput {
        width: 100%;
      }

      .DateInput,
      .TextArea {
        width: 100%;
        min-width: unset;

        input,
        textarea {
          min-width: 100px;
        }
      }

      .expiry-date {
        display: flex;
        align-items: center;

        .Checkbox {
          margin-right: 0;
        }
      }
    }

    .rt-noData {
      display: none;
    }
  }
}
