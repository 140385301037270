@import '~styles/variables';

.ProgressBar {
  position: relative;
  height: 15px;
  background-color: lighten($gray, 45%);
  margin-bottom: 40px;

  &-fill {
    height: 100%;
    transition: width 0.2s ease-in;
  }
}
