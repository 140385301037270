@import '~styles/variables';

.rc-tooltip {
  opacity: 1;

  .rc-tooltip-content {
    max-width: 250px;

    .rc-tooltip-arrow {
      border-top-color: #555;
    }

    .rc-tooltip-inner {
      background: #555;
      box-shadow: 0 0 17px rgba(0, 0, 0, 0.2);
      color: #fff;
      border: none;
      border-radius: 2px;
      min-height: 20px;
      padding: 6px 10px;
    }
  }
}
