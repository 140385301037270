.ExistingUserForm {
  &-message {
    text-align: left;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .DescriptionTable {
    margin-bottom: 10px;
    text-align: left;
  }

  .license-manager-input {
    display: flex;
  }

  .section-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    text-align: left;

    .NumberInput {
      input {
        min-width: 300px;
      }
    }
  }
}
