@import '~styles/variables';

.ChangeTrialForm {
  &-radiobtns {
    .RadioBtn {
      &:last-of-type {
        margin-top: 10px;
      }
    }
  }

  &-remaining {
    margin-top: 30px;

    .DescriptionTable {
      max-width: 300px;
      box-sizing: border-box;
      margin-top: 10px;
      margin-bottom: 0;
      padding: 5px;
      background: $form-element-disabled-background;
      border-color: $form-element-disabled-border;

      tr td:first-of-type {
        width: 50%;
      }
    }
  }
}
