@import '~styles/variables';

.PageNotice {
  position: sticky;
  top: 0;
  z-index: 4;
  border-width: 1px;
  border-style: solid;
  padding: 5px 20px;
  font-family: $font-family;
  color: $text-color-primary;

  .PageNotice-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .PageNotice-body {
    font-size: 14px;
    color: $text-color-secondary;
  }

  &-theme--error {
    background: #f9d0d0;
    border-color: rgba(238, 82, 83, 0.35);

    .PageNotice-title {
      color: #af1112;
    }

    .PageNotice-body {
      color: rgba(#af1112, 0.8);
    }
  }

  &-theme--warning {
    // background: rgba($yellow, .25);
    background: #fbeed1;
    border-color: rgba($yellow, 0.35);

    .PageNotice-title {
      color: darken($yellow, 30%);
    }

    .PageNotice-body {
      color: darken($yellow, 25%);
    }
  }

  &-theme--success {
    background: #c9e7dc;
    border-color: rgba($green, 0.35);

    .PageNotice-title {
      color: darken($green, 25%);
    }

    .PageNotice-body {
      color: darken($green, 15%);
    }
  }

  &-theme--info {
    background: #c8ddf3;
    border-color: rgba($blue, 0.35);

    .PageNotice-title {
      color: darken($blue, 25%);
    }

    .PageNotice-body {
      color: darken($blue, 15%);
    }
  }
}
