@import '~styles/variables';

.ScheduledUpdateContainer {
  padding: 10px 10px 5px;
  border: 1px solid $form-element-border;
  border-top: 5px solid $orange;
  margin: 15px 0 10px;
  font-size: 14px;
  background: $white;

  &-heading {
    font-weight: bold;
  }

  &-body {
    .DescriptionTable {
      margin: 0;
      border: none;
    }
  }

  &-actions {
    .Button {
      margin-right: 20px;
    }
  }
}
