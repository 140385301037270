@import '~styles/variables';

.EmailConfiguration {
  &-sender {
    margin-bottom: 20px;

    .Label {
      margin-top: 0;
    }

    .email {
      font-size: 14px;
    }
  }

  &-title {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .Notice {
    margin-top: 0;
  }

  .test-email {
    margin-bottom: 0px;

    .Label {
      margin-top: 0;
    }

    .test-email-input-wrapper {
      display: flex;
      align-items: center;

      .Button {
        margin: 0;
      }

      .TextInput {
        width: 100%;

        input {
          max-width: 100%;
        }
      }
    }
  }

  .Wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .Configurator {
    margin-bottom: 20px;
    min-width: 540px;
    max-width: 540px;
    padding-right: 40px;

    .event-selector {
      .Label {
        margin-top: 0;
      }
    }

    .TextInput {
      input {
        max-width: 100%;
      }
    }

    .TextArea {
      textarea {
        max-width: 100%;
        min-height: 150px;
      }
    }

    .Selector {
      max-width: 100%;
    }

    .form-row {
      &.checkbox {
        margin: 14px 0 20px;
      }
    }

    .buttons-container {
      margin-top: 30px;

      .Button {
        margin-right: 40px;
      }
    }
  }
}

.flex-row-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}
