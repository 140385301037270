.UserPermissionsFormTitle {
  display: flex;
  align-items: center;

  .confirm-btn {
    margin-left: auto;
  }
}

.UserPermissionsForm {
  .row {
    &.input-cont {
    }

    &.checkbox-cont {
      margin-top: 10px;
      margin-bottom: 20px;
      padding-bottom: 1px;
      border-bottom: 1px solid #ededed;

      .Checkbox {
        width: 15%;
      }
    }
  }
}
