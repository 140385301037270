@import '~styles/variables';
@import '~styles/helpers';

.CustomerAccountRedirectUriForm {
  .redirect-row {
    display: flex;
    flex-direction: column;

    .TextInput {
      input {
        max-width: 400px;
      }
    }
  }
}
