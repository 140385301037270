@import '~styles/variables';
@import '~styles/helpers';

.Forbidden {
  @include flexMiddleCenter;
  padding: 100px 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px;

    .Subtitle {
      margin-top: 10px;
      text-align: center;
    }
  }
}
