@import '~styles/variables';

.Checkbox {
  display: inline-block;
  margin-right: 20px;

  &.Checkbox-disabled {
    label {
      color: $gray;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: $text-color-primary;
    text-align: left;
  }

  input {
    position: relative;
    appearance: none;
    height: 18px;
    width: 18px;
    border: 1px solid $form-element-border;
    outline: none;
    vertical-align: bottom;
    cursor: pointer;
    margin-right: 6px;
    background-color: #fff;
    margin-left: 0;

    &.has-error {
      border-color: $red;
    }

    &:hover,
    &:focus {
      border: 1px solid $form-element-border-focus;
    }

    &:checked {
      border: 1px solid $form-element-border-focus;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHRpdGxlPmljb25zPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjEgMzIuMzUgNi4zOCAyNC43MSAxOS41MyAzMy45OCA0MS4zNiAzIDQ5IDguMzggMjEuNzkgNDcgMSAzMi4zNSIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  .Tooltip {
    display: inline-block;
    svg {
      cursor: help;
      vertical-align: super;
      margin-left: 5px;
    }
  }
}
