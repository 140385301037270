@import '~styles/variables';
.PaymentMethod {
  .payment-method-card {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 30px 20px 10px;
    border: 1px solid #ededed;
    border-radius: 4px;
    min-width: 200px;
    .card-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      border-right: 1px solid rgba(112, 112, 112, 0.15);
      margin-right: 60px;
      flex: 1;
      margin-bottom: 20px;
      max-width: 250px;
      .card-last4 {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 13px;
        span {
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
      .card-expiry {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 10px;
        color: $text-color-secondary;
        .card-expiration-date {
          margin-left: 3px;
        }
      }
    }
    .user-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 300px;
      margin-bottom: 20px;
      .user-details-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .user-details-column {
          min-width: 25%;
          .Label {
            margin: 0 0 2px;
          }
          div {
            font-size: 13px;
          }
        }
      }
    }
    .payment-action {
      align-self: center;
      display: flex;
      align-items: flex-start;
      flex: 2;
      min-width: 200px;
      margin-bottom: 20px;
      flex-direction: column;
      .Button {
        min-width: 205px;
      }
    }
  }
}
