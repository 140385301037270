.AirgapPortalData {
  &-domain {
    margin-bottom: 20px;

    .Label {
      margin-top: 0;
    }

    .domain {
      font-size: 14px;
    }
  }

  .config-section {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
  }

  .style-config {
    .section-header {
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
      background: #dadada;
    }

    .DescriptionTable {
      margin-bottom: 0;
    }
  }

  &-actions {
    margin-top: 30px;

    .Button {
      margin-right: 30px;
    }
  }
}
