@import '~styles/variables';

.AssignLicenseUser {
  .form-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and(max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }

    .Subtitle {
      margin: 10px 0;
    }

    .TextInput,
    .NumberInput {
      input {
        max-width: unset;
        min-width: unset;
        box-sizing: border-box;
      }
    }

    .hidden {
      visibility: hidden;
    }

    .left {
      flex: 1;

      @media screen and(max-width: 1200px) {
        margin-right: 0;
        min-height: unset;
        width: 100%;
      }

      .Checkbox {
        margin-top: 0 !important;
      }
    }

    .divider {
      display: none;
      margin: 30px 30px 10px;
      background: $form-element-border;
      width: 1px;

      @media screen and(max-width: 1200px) {
        display: none;
      }

      &.hidden {
        display: block;
        visibility: hidden;
      }

      &.active {
        display: block;

        @media screen and(max-width: 1200px) {
          display: none;
        }
      }
    }

    .right {
      flex: 1;

      @media screen and(max-width: 1200px) {
        margin-left: 0;
        min-height: unset;
        width: 100%;
      }

      &.hidden {
        visibility: hidden;
      }

      .license-manager-input {
        margin-top: 12px;
      }

      .password-container {
        .Label {
          margin-top: 5px;
        }
      }
    }
  }
}
