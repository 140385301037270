@import '~styles/variables';
@import '~styles/helpers';

.SalesforceInit {
  .TextInput {
    margin-bottom: 16px;

    input {
      max-width: 400px;
    }
  }
}
