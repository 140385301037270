@import '~styles/variables';

.RichTextEditorToolbar {
  display: flex;
  align-items: center;

  &-tools {
    display: flex;
    flex-wrap: wrap;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-right: 6px;
      margin-bottom: 4px;
      width: 30px;
      height: 30px;
      padding: 6px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: $form-element-border;
      }
    }
  }

  &-button {
    &.active {
      background-color: $form-element-border;
    }
  }
}
