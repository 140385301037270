@import '~styles/variables';

.CompanySelector {
  width: calc(#{$Sidebar-width} - #{$logo-width});

  .company-selector {
    margin: 0;
    position: relative;
    background-color: $gray;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;

    .company-selector-inner__control {
      display: flex;
      height: $TopHeader-height;
      border: none;
      border-radius: 0;
      background-color: #555;
      width: 100%;

      .company-selector-inner__single-value {
        color: $white;
        font-size: 16px;
        font-weight: 600;
      }

      .company-selector-inner__input {
        color: $white;
        font-size: 16px;

        input {
          font-weight: 600;
        }
      }

      .company-selector-inner__indicator-separator {
        display: none;
      }
    }

    &.company-selector-inner--is-disabled {
      .company-selector-inner__dropdown-indicator {
        opacity: 0;
      }
    }

    .company-selector-inner__menu {
      background-color: #84888b;
      border-radius: 0;
      margin: 0;
    }
  }
}
