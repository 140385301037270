@import '~styles/variables';
@import '~styles/helpers';

.SimpleFilterTag {
  display: flex;
  align-items: center;
  @include appearance(none);
  cursor: pointer;
  outline: none;
  margin-bottom: 5px;
  margin-right: 10px;
  border-width: 1px;
  background: rgba($yellow, 0.25);
  border-color: rgba($yellow, 0.35);
  color: darken($yellow, 30%);

  &:disabled {
    background: rgba($yellow, 0.05);
  }

  p {
    margin: 2px 0;
  }

  svg {
    margin-left: 4px;
    fill: darken($yellow, 40%);
  }
}
