@import '~styles/variables';

.SecurityContainer {
  .section-header {
  }

  &-twofa {
    margin-top: 40px;

    .image-container {
      svg {
        max-width: 300px;
        width: 100%;
        height: auto;
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }

    ul {
      padding-inline-start: 15px;
      font-size: 14px;
      color: $text-color-secondary;

      li {
        margin-bottom: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
