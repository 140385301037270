@import '~styles/variables';

.CompanySidebar {
  margin: 20px 10px 40px 20px;

  .NavLink {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: $text-color-primary;

    header {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin: 10px 0;

      .NavLink-icon {
        display: flex;
        align-items: center;
        width: 30px;
        margin-right: 10px;
      }
    }

    &:hover {
      color: #111;
    }

    &-active {
      color: $blue;

      &:hover {
        color: $blue;
      }

      .NavLink-submenu {
        display: block;
      }

      svg {
        fill: $blue;
      }

      .CompanyConfig {
        svg {
          fill: none;
          stroke: $blue;
        }
      }
    }
  }
}

.version-cont__sidebar {
  position: fixed;
  bottom: 0;
  width: 249px;
  padding: 5px;
  background-color: #f5f5f5;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08);

  a {
    color: $text-color-secondary;
    font-size: 11px;
    text-align: center;
    margin: 0;
    text-decoration: underline;
  }
}

.Company-plan {
  font-size: 12px;
  padding: 3px 5px 3px 20px;
  background-color: #f4f8fc;

  .plan-type {
    color: $text-color-secondary;
    font-weight: 500;
  }

  .acc-type {
    margin-top: 5px;
    font-weight: 400;
    color: $text-color-secondary;
  }
}
