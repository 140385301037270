@import '~styles/variables';

.StandardSubscriptionForm {
  &-form {
    .Selector {
      max-width: 400px;
    }

    .DateInput {
      max-width: 400px;
      width: 100%;

      input {
        max-width: 400px;
      }
    }

    .InputErrorMessage {
      margin-bottom: 12px;
    }

    .tax-rate-wrapper {
      .Checkbox {
        margin-top: 10px;
      }
    }

    .Checkbox {
      .InputErrorMessage {
        margin-bottom: 5px;
      }
    }

    .total {
      font-size: 14px;
      color: $text-color-secondary;
      padding: 12px 0;
    }
  }
}
