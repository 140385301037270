@import '~styles/variables';

.BaseChart {
  padding: 10px 0;

  &-heading {
    padding: 0 10px 5px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
    }

    .subtitle {
      font-size: 12px;
      color: $text-color-secondary;
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e9ea;

    .ContentLoader {
      padding: 0;
    }
  }
}
