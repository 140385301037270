@import '~styles/variables';

.DashboardButton {
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    color: $text-color-primary;
    text-decoration: none;
    padding: 12px 20px;
    margin: 15px;
    border: 1px solid $form-element-border;
    background-color: $white;
    height: 120px;
    width: 180px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid $form-element-border-focus;
    }

    svg {
      margin-bottom: 10px;
      flex-shrink: 0;
    }

    h3 {
      margin: 0;
      font-weight: 500;
    }

    p {
      margin: 5px 0 0;

      span {
        &:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
}
