@import '~styles/variables';
@import '~styles/helpers';

.TopHeader {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background: #555;
  height: $TopHeader-height;
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  padding: 0 30px 0 0;

  .TopHeader-primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $Sidebar-width;

    & > .logo {
      height: $TopHeader-height;
      width: $Sidebar-width-sm;
      background: #555;

      a {
        @include flexMiddleCenter;
        height: $TopHeader-height;
      }
    }
  }

  .TopHeader-secondary {
    display: flex;
    align-items: center;

    .docs-btn {
      margin-right: 20px;

      a {
        background: #ffffff;
        border: 1px solid #ccc;
        color: #555;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        height: 20px;
        padding: 0 20px;
        margin: 10px 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        outline: 0;
        cursor: pointer;
        transition: 150ms all ease-in-out;
        position: relative;
        text-decoration: none;
      }
    }
  }
}
