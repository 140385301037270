.EnterprisePlanTypeBox {
  .Button {
    margin-top: 2px;
  }

  .PlanTypeBox-price {
    height: 138px !important;
  }

  .PlanTypeBox-btn {
    .Button {
      padding: 0 36px;
    }
  }
}
