.EditProductFeatureForm {
  .form-row {
    .Checkbox {
      margin-top: 14px;
    }

    .Label {
      margin-top: 5px;
    }

    .Selector {
      max-width: 300px;
    }
  }
}
