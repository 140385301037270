@import '~styles/variables';

.ProductInformations {
  &-form {
    .form-row {
      display: flex;

      &.file-checkbox {
        margin-top: 10px;
      }

      .form-column {
        margin-right: 40px;

        &.right-input {
          .TextInput,
          .NumberInput {
            max-width: 260px;

            input {
              max-width: 260px;
              min-width: 260px;
            }
          }
        }

        .Selector {
          min-width: 300px;
        }
      }
    }
  }

  &-actions {
    margin-top: 30px;
  }
}
