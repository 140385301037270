@import '~styles/variables';
@import '~styles/helpers';

.LicensesFeaturesImport {
  .template-link {
    margin: 10px 0;

    a {
      font-size: 14px;
    }
  }

  .docs-link {
    display: flex;

    p {
      margin: 0;
      font-size: 14px;
      color: $text-color-secondary;
    }

    a {
      color: $text-color-secondary;
      font-size: 14px;
      margin-left: 3px;
    }
  }

  &-list {
    margin-top: 20px;

    .heading {
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}
