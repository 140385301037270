@import '~styles/variables';

.react-tabs {
  &__tab-list {
    border-bottom: 2px solid #e8e8e8;
  }

  &__tab {
    font-weight: 500;
    padding: 10px 30px;
    outline: 0;
    bottom: -1px;
    border: 0;
    border-bottom: 2px solid transparent;
    color: rgba($text-color-primary, 0.8);
    font-size: 15px;

    &:focus,
    &:hover {
      border: 0;
      border-bottom: 2px solid transparent;
      box-shadow: none;
      outline: 0;
      color: $text-color-primary;
    }

    &:after {
      display: none;
    }

    &--selected {
      background: transparent;
      color: $blue;
      border: 0;
      border-bottom: 2px solid $blue;
      outline: 0;

      &:focus,
      &:hover {
        border: 0;
        border-bottom: 2px solid $blue;
        box-shadow: none;
        outline: 0;
        color: $blue;
      }
    }

    &--disabled {
      color: rgba($text-color-primary, 0.5);
      cursor: not-allowed;

      &:hover {
        color: rgba($text-color-primary, 0.5);
      }
    }
  }

  &__tab-panel {
    &--selected {
      padding: 10px;

      .Button {
        margin-top: 0;
      }
    }
  }
}
