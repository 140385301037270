.TaxDetailsForm {
  .tax-type-fields {
    .row {
      margin-bottom: 10px;

      .TextInput {
        input {
          max-width: 400px;
        }
      }
    }
  }
}
