.BulkActionsDropdown {
  position: relative;
  display: inline-block;

  &-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid #e2e8f0;
    background-color: #fff;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 150px;

    &:hover:not(.is-disabled) {
      background-color: #f7fafc;
      border-color: #cbd5e0;
    }

    &.is-disabled {
      background-color: #f7fafc;
      color: #a0aec0;
      cursor: not-allowed;
      border-color: #edf2f7;
    }
  }

  &-chevron {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }

  &-menu {
    position: absolute;
    left: 0;
    margin-top: 8px;
    min-width: 200px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #e2e8f0;
    z-index: 10;
  }

  &-menuContent {
    padding: 4px 0;
  }

  &-menuItem {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    font-size: 14px;
    color: #4a5568;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f7fafc;
      color: #2d3748;
    }

    // Add a subtle separator between items
    &:not(:last-child) {
      border-bottom: 1px solid #f0f4f8;
    }
  }
}
