.Breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  font-size: 13px;
  font-weight: 500;

  a {
    text-decoration: none;

    &:last-of-type {
      // font-weight: 700;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
