@import '~styles/variables';

.DatePicker {
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap;

  &.disabled {
    pointer-events: none;

    .date-show-cont {
      background-color: $form-element-disabled-background;
      cursor: progress;
    }

    .divider {
      background-color: $form-element-disabled-background;
    }
  }

  .date-show-cont {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    padding: 10px;
    border: 1px solid $form-element-border;
    min-width: 120px;
    color: $text-color-primary;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    background: #fff;

    svg {
      margin-right: 20px;
    }

    .date-show {
      .divider {
        margin: 0 5px;
        background: #fff;
        display: inline;
      }
    }
  }

  .Dropdown {
    background-color: $background;
    padding: 0;
    box-shadow: none;
    top: 42px;
    z-index: 9;
    box-shadow: $shadow;

    &::before {
      content: none;
    }

    .dropdown-inner {
      display: flex;

      .date-options-cont {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border: 1px solid $form-element-border;
        padding: 6px;
        order: 2;

        button {
          border: none;
          cursor: pointer;
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          background-color: transparent;
          text-align: right;
          outline: none;
          color: $text-color-primary;
          border-radius: 3px;

          &:hover,
          &.active {
            background-color: $select-option-hover;
            color: #fff;
          }
        }
      }

      .date-picker-cont {
        display: inline;
        border: 1px solid $form-element-border;
        border-right: none;

        .Button {
          display: block;
          margin-left: auto;
          margin-right: 20px;
          margin-top: 0;
        }

        .DateRangePickerInput {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          border: none;
          background-color: $background;

          .DateInput {
            display: flex;
            width: 280px;
            margin-top: 10px;
            padding: 0 15px;
            box-sizing: border-box;
            background: transparent;
            order: 1;

            .DateInput_input {
              border: 1px solid $form-element-border;
              padding: 6px 11px 6px;
              text-align: right;
              font-size: 14px;
              font-weight: 500;
              min-width: unset;
              color: #777;
            }
          }
        }

        .DateRangePickerInput_arrow {
          display: none;
        }

        .DateRangePicker_picker {
          position: initial;
          order: 3;

          .DayPicker__withBorder {
            box-shadow: none;
            border-radius: 0;
          }
        }

        .DayPicker__hidden {
          visibility: visible;
        }

        .DayPicker_transitionContainer {
          height: 280px !important;
          background-color: $background;

          .CalendarMonthGrid {
            background-color: $background;

            .CalendarMonth {
              background-color: $background;

              tr {
                td {
                  height: 32px !important;
                }
              }
            }
          }
        }

        .DayPickerKeyboardShortcuts_show {
          display: none;
        }

        .DateInput_fang {
          display: none;
        }

        .CalendarDay {
          &__selected {
            background: $blue;
            border: 1px solid darken($blue, 4);

            &_span {
              background: lighten($blue, 20);
              border: 1px solid lighten($blue, 8);
            }
          }

          &__hovered {
            // background: $blue;
            // border: 1px solid darken($blue, 4);

            &_span {
              background: lighten($blue, 35);
              border: 1px solid lighten($blue, 8);
            }
          }
        }
      }
    }
  }
}
