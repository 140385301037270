.PlatformAuditLog {
  .Filters-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .log-actor {
    margin-left: 10px;
  }
}
