@import '~styles/variables';

.BillingConfiguration {
  .section-header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .section-body {
    margin-top: 2px;
    margin-bottom: 25px;

    .list-header {
      margin-bottom: 5px;
    }
  }
}
