@import '~styles/variables';

.WidgetsContainer {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;

  header {
    font-size: 13px;
    font-weight: 400;
    color: $text-color-secondary;
  }

  main {
    text-align: right;
    font-size: 30px;
    font-weight: 600;
  }
}
