@import '~styles/variables';

.UserLabelsForm {
  .new-label-btn,
  .back-label-btn {
    .Button {
      padding: 0;
      color: $blue;

      span {
        margin-right: 3px;
      }
    }
  }
}
