@import '~styles/variables';
@import '~styles/helpers';

.List {
  .ReactTable {
    background: #fff;
    border: 1px solid #ededed;
    font-size: 14px;

    .rt-table {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
    }

    .rt-thead.-header {
      box-shadow: none;
      font-weight: 600;
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
      order: 1;

      .rt-th {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        color: $text-color-primary;
        outline: 0;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-word;

        &.-sort-asc {
          box-shadow: none;
          background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsMTk3LjI4N0wxNTkuMDI4LDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMywxLjgwNy0xMi44NSw1LjQyNEw1LjQyNCwxOTcuMjg3ICAgQzEuODA3LDIwMC45MDQsMCwyMDUuMTg2LDAsMjEwLjEzNHMxLjgwNyw5LjIzMyw1LjQyNCwxMi44NDdjMy42MjEsMy42MTcsNy45MDIsNS40MjUsMTIuODUsNS40MjVoMjU1LjgxMyAgIGM0Ljk0OSwwLDkuMjMzLTEuODA4LDEyLjg0OC01LjQyNWMzLjYxMy0zLjYxMyw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDdTMjkwLjU0OCwyMDAuOTA0LDI4Ni45MzUsMTk3LjI4N3oiIGZpbGw9IiM1NTU1NTUiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
          background-repeat: no-repeat;
          background-position: calc(100% - 6px) center;
          background-size: 10px;
          background-color: rgba($yellow, 0.2);
        }

        &.-sort-desc {
          box-shadow: none;
          background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGZpbGw9IiM1NTU1NTUiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
          background-repeat: no-repeat;
          background-position: calc(100% - 6px) center;
          background-size: 10px;
          background-color: rgba($yellow, 0.2);
        }

        &.text-center {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.editable-column {
          position: relative;
          padding-left: 18px;

          div {
            width: 100%;
          }
          .custom-header {
            display: flex;
            align-items: center;

            svg {
              flex-shrink: 0;
              position: absolute;
              left: 2px;
              top: 4px;
            }
          }
        }

        .tooltip-header {
          display: flex;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .rt-tbody {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);

      .rt-tr {
        cursor: pointer;

        .rt-td {
          padding: 8px 10px;
          white-space: pre-wrap;
          font-size: 13px;
          word-break: break-word;

          &.text-center {
            text-align: center;
            align-items: center;
          }

          &.wrap {
            white-space: pre-wrap;
          }

          &.select-container {
            padding: 0;

            button {
              width: 100%;
              height: 100%;
              padding: 8px 10px;

              .Checkbox {
                margin: 0;
                z-index: 0;
                pointer-events: none;

                .InputErrorMessage {
                  margin-top: 0;
                }
              }
            }
          }

          &.action-menu {
            overflow: visible;
          }

          &.rt-expandable {
            padding: 0;

            .rt-expander {
              padding: 8px 10px;
              height: 100%;
              margin: 0;
            }
          }

          &.customer-label {
            padding: 0 4px 0 10px;

            .customerLabels {
              display: flex;
              align-items: center;
              height: 100%;

              .label-titles {
                margin-right: auto;
              }

              .label-colors-wrapper {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                max-width: 30px;
                flex-shrink: 0;
              }

              .label-color {
                width: 10px;
                height: 10px;
                margin: 2px;
              }
            }
          }

          svg {
            vertical-align: middle;
          }

          .Button {
            margin-bottom: 0;
          }
        }

        &.-odd {
          background: #f5f5f5;
        }
      }

      .-odd + .SubComponent {
        background: #f5f5f5;
      }

      .SubComponent {
        .DescriptionTable {
          margin: 20px;
          margin-top: 5px;
          box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
        }

        &-actions {
          padding: 0 20px;
        }
      }
    }

    .-pagination {
      box-shadow: none;
      border-top: 1px solid #ededed;
    }

    .edit-button {
      @include appearance(none);
      background: none;
      border: 0;
      cursor: pointer;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 4px;
      opacity: 0.7;
      outline: 0;

      &:hover {
        opacity: 1;
      }
    }

    .table-button,
    .reset-button {
      -webkit-appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      width: 100%;
      height: 100%;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    .delete-button,
    .table-select {
      @include appearance(none);
      background: none;
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 0;
    }

    .color-cell {
      display: flex;
      align-items: center;

      .color-container {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
  &.List-clickable {
    .ReactTable {
      .rt-tbody {
        .rt-tr {
          &:hover {
            background: rgba($yellow, 0.15);
          }
        }
      }
    }
  }

  // .ReactTable .-loading {
  //   background: rgba(255, 255, 255, 0.95);

  //   .ContentLoader {
  //     padding: 0;
  //   }
  // }

  .ReactTable .-loading > div {
    top: 20px;
  }
}
