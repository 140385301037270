.form-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.ExportOrderForm-filters {
  .title {
    font-weight: 600;
    margin: 20px 0 6px;
    font-size: 14px;
  }

  .DescriptionTable {
    margin-bottom: 0;
  }

  .Button {
    margin-top: 6px;
    padding: 0;

    svg {
      margin-right: 3px;
    }
  }
}
