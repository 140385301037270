.EditMgmtKeyForm {
  .mgmt-key {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
  }
  .readonly-checkbox {
    margin-top: 12px;
  }
}
