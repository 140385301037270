@import '~styles/variables';

.VerifyProduct {
  &-details {
    .product-information {
      background: $white;
      max-width: 738px;
      border: 1px solid #e3e3e3;
      border-bottom: none;
      padding: 20px 0 20px 20px;

      .product-name {
        font-size: 18px;
        font-weight: 600;
      }
      .product-code {
        color: $text-color-secondary;
      }
    }

    .product-details {
      max-width: 760px;

      .installation-files-row {
        vertical-align: top;

        .InstallationFileData {
          div {
            margin: 2px;

            span {
              font-size: 12px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }

  &-actions {
    margin-top: 30px;

    .Button {
      margin-right: 40px;
    }
  }
}
