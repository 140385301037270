@import '~styles/variables';

.Settings {
  section {
    margin-bottom: 10px;
  }

  .heading {
    font-weight: 500;
  }

  .description {
    color: $text-color-secondary;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .actions {
    .Button {
      margin-right: 20px;
    }
  }

  &-data-wipe {
    .data-type-selector {
      margin-bottom: 10px;
    }
  }
}
