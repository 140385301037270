@import '~styles/variables';

.AnalyticsProduct {
  margin-top: 20px;

  .individual-actions-charts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .individual-chart {
      width: 48%;
      margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .individual-chart {
        width: 100%;
      }
    }
  }
}
