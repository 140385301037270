@import '~styles/variables';

.ProfileContainer {
  .Sidebar-sm {
    .CompanySidebar {
      margin-top: 0;
    }
  }

  .ProfileContainer-link {
    &:first-of-type {
      margin-top: 40px;
    }
  }
}
