@import '~styles/variables';

.Dashboard {
  .dashboard-navigation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      &.multi {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  &-activity {
    margin-bottom: 120px;
    max-width: 1100px;

    .Subtitle {
      margin-top: 30px;
      margin-bottom: 0;
    }

    .chart-container {
      margin-top: 20px;

      .recharts-legend-wrapper {
        margin-left: 40px !important;
        bottom: -10px !important;

        li {
          margin-right: 20px !important;
        }
      }
    }
  }
}
