@import '~styles/variables';

.PermissionMissingNotificationTitle {
  display: flex;
  flex-wrap: wrap;

  .label {
    margin-right: 6px;
  }

  .value {
    color: $red;
  }
}
