@import '~styles/variables';
@import '~styles/helpers';

.EventStatus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 6px;
  color: #f7f7f7;
  width: 100%;
  max-width: 100px;

  &.success {
    background: rgba($green, 0.25);
    border-color: rgba($green, 0.35);
    color: darken($green, 25%);
  }

  &.failed {
    background: rgba($red, 0.25);
    border-color: rgba($red, 0.35);
    color: darken($red, 25%);
  }
}
