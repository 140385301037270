@import '~styles/variables';

.ConsumptionChart {
  margin-bottom: 40px;

  .recharts-reference-line {
    text {
      font-size: 11px;
      // font-weight: 500;
      color: $text-color-secondary;
    }
  }

  &-actions {
    margin-bottom: 10px;

    button {
      margin-right: 20px;
    }
  }
}
